.text-overflow-ellipsis {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}

.thanksful-text {
    font-size: 1.5em;
    display: block;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
}

.reset-event-search {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 1em;
    border-radius: 15px;
    background-color: #e9e9e9;
    color: white;
}

.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: black;
    background-color: black;
    z-index: 1000;
    opacity: 0.2;
}

.choices-width-125 {
    .button {
        width: 125px !important;
    }
}

.choices-width-175 {
    .button {
        width: 175px !important;
    }
}

/*
This is just workaround for fix flickers on Internet Explorer.
This will be fixed in angular-animate 1.5.9 version.(https://github.com/angular/angular.js/issues/14015).
So we need to remove following code when we update angular-animate version.
*/
.ng-hide.ng-hide-animate {
    display: none !important;
}

.table-header {
    width: 70%;
    background-color: #4f535f;
    color: #e8e8f0;
}

.block {
    display: block;
}

.bg-default-mobile {
    min-height: 100%;
    height: 100%;
}

.full-screen {
    height: 100%;
}

.video-service-icon,
.text-service-icon {
  width: 50px;
  height: 60px;

  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (max-width: 360px) {
    width: 30px;
    height: 35px;
  }
}

.text-service-icon {
    background-image: url('../assets/img/icons/text.png');
}

.video-service-icon {
    background-image: url('../assets/img/icons/video.png');
}

.diary-flow {
  padding-top: 0;
  margin-top: 0;
  height: 100%;
  color: #4f535f;
  background-color: #cfdce4;
  min-height: 400px;

  color: #4f535f;
  background-color: #cfdce4;

  @media @screen-xs-min {
    padding-top: 0;
    margin-top: 0;
    height: 80vh;
    min-height: 400px;
  }

  @media @screen-sm-min {
    font-size: 16px;
  }

  &.with-youtube-logo {
    .diary-questions {
      .question {
        .question-heading {
          @media @screen-xs-max {
            .calc(width, "100% - 45px");
          }
        }
      }
    }
  }

  // todo: i assured we can remove safely div here
  div.content {
    color: #6f7075;
    white-space: nowrap;
  }

  .content-container {
    position: relative;
    overflow: hidden;

    &.allcp-form {
      .checkbox {
        top: 0;
      }
    }

    .content-backgrounds {
      position: absolute;
      width: 100%;
      height: 100%;

      div {
        height: 100%;

        &:nth-child(even) {
          background-color: #E5E4E9;
        }
        &:nth-child(odd) {
          background-color: #F7F7F7;
        }
      }
    }
  }

  .diary-questions {
    height: 100%;
    width: 100%;
    padding: 55px 80px 50px;

    @media @screen-sm-max {
      position: relative;
    }

    @media @screen-xs-max {
      padding: 30px 10px 50px;
    }

    .question-q-block {
      position: relative;
      height: 70px;
      width: 60px;
      margin-left: -80px;
      margin-top: -55px;
      color: #e8e8f0;
      background-color: #4f535f;

      @media @screen-xs-max {
        height: 70px;
        width: 40px;
        margin-left: -40px;
        margin-top: -55px;
        color: #e8e8f0;
        background-color: #4f535f;

        opacity: 0;
      }

      .q-block-text {
        display: inline-block;
        position: absolute;
        right: 0;
        bottom: 0;
        line-height: 1;
        font-size: 45px !important;

        @media @screen-xs-max {
          font-size: 30px!important;

          p {
            margin-bottom: 5px;
          }
        }
      }
    }

    .question {
      .calc(height, "100% + 40px");
      .flex-block();
      .flex-direction(column);
      .justify-content(space-between);
      margin-top: -40px;

      &.show-helper-text {
        .question-heading {
          .question-info {
            display: block!important;
          }
        }
      }

      &.normal-question-info {
        .question-heading {
          .question-info {
            font-weight: normal;
          }
        }
      }


      .question-heading {
        width: 100%;
        height: auto;
        line-height: 1.4;
        .flex-shrink(0);
        padding-top: 20px;
        font-size: 16px;

        @media @screen-xs-max {
          padding-left: 5px;
        }

        .question-text {
          line-height: 1.5;
        }

        .question-info {
          font-weight: 700;
          font-style: italic;

          display: none !important;

          &.simple-question-info {
            font-weight: normal;
            font-style: normal;
            display: block !important;
          }
        }
      }

      .body-header {
        margin-top: 10px;

        .h40-80 {
          height: 80px;

          @media @screen-xs-max {
            height: 40px;
          }
        }

        &.programme-info div {
          background-color: @white;
          .align-items(center);
        }

        div, img {
          height: 100%;
        }

        div img {
          max-height: 100%;
          max-width: 100%;
          height: auto;
        }

        div.date-bar, .date-bar img, .date-bar div {
          height: 50px;
        }
      }

      .question-body {
        .calc(height, "100% - 70px");
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 25px;
        margin-top: 10px;
        position: relative;

        .flex-block;
        .flex-shrink(1);

        @media @screen-xs-max {
          margin-top: 10px;
        }

        .illustrated-choices {
          .flex-block;
          max-width: 800px;
          margin: auto;

          @media @screen-sm-max {
            .flex-direction(column);
          }

          .illustrations {
            width: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            .ng-image-gallery {
              width: 100%;
              .ng-image-gallery-thumbnails {
                .flex-block;
                .flex-direction(row);
                .flex-wrap(wrap);
                .justify-content(center);
                .align-content(center);

                .thumb {
                  width: 50%!important;
                  min-width: 180px;
                  height: 130px!important;
                  margin: 0;
                }
              }

              @media @screen-sm-max {
                margin-bottom: 15px;
              }
            }

          }

          .gap {
            width: 30px;
            flex-shrink: 0;
          }
          .choices {
            .flex-block;
            .flex-direction(column);
            .justify-content(center);

            width: 100%;
            .panel {
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        &.black-body {
          background-color: #484848;
          color: @white;
        }

        &.not-found {
          background-color: white;
          padding-top: 0;
          padding-bottom: 0;

          /*
          width: 100%;
          display: table;
          //*/
          .flex-block;
          .align-items(center);
          .justify-content(center);

          .mCustomScrollBox {
            .flex-block;
            height: 100%;

            .mCSB_container {
              margin: auto;
            }
          }

          .not-found-message {
            text-align: center;
            padding-right: 10px;
            padding-left: 10px;

            .flex-block;
            .flex-direction(column);
            .justify-content(center);
            .align-items(center);
          }
        }

        .slider-wrapper {
          .ui-slider {
            height: 30px;

            .ui-slider-handle {
              height: 35px;
              width: 35px;
            }

            &-horizontal .ui-slider-handle {
              margin-left: -1.15em;
            }
          }
        }

        .mCustomScrollBox {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;

          &.mCSB_vertical_horizontal,
          &.mCSB_horizontal {
            .calc(height, "100% - 20px");
          }

          &.mCSB_vertical_horizontal,
          &.mCSB_vertical {
            &.mCSB_outside .mCSB_container:not(.mCS_no_scrollbar_y) {
              @media @screen-xs-max {
                padding-right: 22px;
              }
            }
          }
        }

        &.full-height {
          &.mCustomScrollbar {
            .mCustomScrollBox {
              &.mCSB_vertical_horizontal,
              &.mCSB_horizontal {
                height: 100%;
              }
            }
          }
        }

        .mCSB_scrollTools {
          opacity: 1;

          &.mCSB_scrollTools_horizontal {
            bottom: 2px !important;
            width: 100%;

            a + .mCSB_draggerContainer {
              margin-top: -7px;
            }
          }
        }

        &.mCustomScrollbar {
          overflow-y: auto;
          overflow-x: hidden;
          overflow: inherit!important;

          .mCSB_vertical_horizontal {
            &+.mCSB_scrollTools_vertical {
              @media @screen-xs-max {
                .calc(height, "100% - 20px");
              }
            }
          }

          .mCSB_outside+.mCSB_scrollTools {
            right: -40px;

            @media @screen-xs-max {
              right: 2px;
            }
          }

          .mCSB_scrollTools {
            .mCSB_buttonUp,
            .mCSB_buttonDown,
            .mCSB_buttonLeft,
            .mCSB_buttonRight {
              background-color: @white;
              opacity: 1;
              width: 18px;
            }

            .mCSB_buttonUp {
              background-position: -47px 0px;
            }

            .mCSB_buttonDown {
              background-position: -47px -16px;
            }

            .mCSB_buttonLeft {
              background-position: -61px -40px;
            }

            .mCSB_buttonRight {
              background-position: -61px -56px;
            }

            &.mCSB_scrollTools_vertical {
              z-index: 1;

              .mCSB_dragger {
                .mCSB_dragger_bar {
                  width: 18px;
                }
              }
            }

            &.mCSB_scrollTools_horizontal {
              .mCSB_dragger {
                .mCSB_dragger_bar {
                  height: 18px;
                }
              }
            }

            .mCSB_dragger {
              .mCSB_dragger_bar {
                background-color: #cfdce4;
                border-radius: 0;
                border: 2px solid @white;
              }

              &.mCSB_dragger_onDrag .mCSB_dragger_bar {
                background-color: #cfdce4;
              }
            }

            .mCSB_draggerRail {
              background-color: @white;
              width: 18px;
              border-radius: 0;
            }
          }

          .mCustomScrollBox {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;

            &.mCSB_vertical_horizontal,
            &.mCSB_horizontal {
              .calc(height, "100% - 20px");
            }

            &.mCS-light.mCSB_vertical.mCSB_outside {
              @media @screen-xs-max {
                padding-right: 5px;
              }
            }
          }

          .row.mn::before {
            content: none;
          }
        }

        &.mCS_no_scrollbar {
          .mCustomScrollBox.mCS-light.mCSB_vertical.mCSB_outside {
            @media @screen-xs-max {
              padding-right: 0;
            }
          }
        }

        .row {
          margin-right: -15px!important;
          margin-left: -15px!important;
          display: block;
          font-size: 0;

          @media @screen-md-max {
            margin-right: -5px!important;
            margin-left: -5px!important;
          }

          @media @screen-xs-max {
            margin-right: 0px!important;
          }

          .switch {
            padding-left: 15px;
            padding-right: 15px;
            margin-left: 0;
            margin-right: 0;

            @media screen and  (max-width: 1030px) {
              padding-right: 5px;
              padding-left: 5px;
            }

            &.small-switch {
              margin-bottom: 1em;

              .switch-body {
                height: auto;
                padding-top: 1em;
                padding-bottom: 1em;

                &-content {
                  position: relative;
                  top: auto;
                  .transform(unset);
                }
              }
            }
          }
        }

        &.question-table {
          .mCustomScrollBox .mCSB_container {
            padding: 5px;
          }
          &[table-with-fixed-columns-rows=""] .mCustomScrollBox .mCSB_container {
            padding: 0;
          }
        }

        .switch, .programme {
          display: inline-block !important;
          background: none;

          &.ng-hide {
            display: none!important;
          }

          a, a:hover {
            color: white;
          }

          .md-ripple-container {
            border-radius: 0;
          }

          .md-button {
            padding: 0;
            margin: 0;
            width: 100%;
            text-transform: none;
            border-radius: 0;
          }
        }

        .switch {
          margin-bottom: 20px;

          @media screen and  (max-width: 1030px) {
            margin-bottom: 10px;
          }

          &.flexible {
            display: inline-block;
            width: 20%;
            .box-sizing(border-box);
            text-align: center;
            font-size: 16px;
            vertical-align: text-bottom;

            @media screen and (min-width: 830px) and (max-width: 1030px) {
              width: 25%;
            }

            @media screen and (min-width: 631px) and (max-width: 830px) {
              width: 33%;
            }

            @media screen and  (max-width: 630px) {
              width: 50%;
            }
          }

          &.increased-images {
            .image {
              height: 75px;
            }
            .text {
              &.image-text-restriction {
                max-height: 35px;
              }
            }
          }

          .image {
            height: 55px;
            background: center/contain no-repeat;
          }

          .text {
            font-weight: 400;

            white-space: pre-wrap;
            line-height: 1.2em;
            line-height: initial;

            @media @screen-xs-max {
              line-height: 15px;
            }
            &.image-text-restriction {
              max-height: 55px;
            }
          }

          .switch-body {
            height: 120px;
            border: 5px solid @white;
            color: #6f7075;
            background-color: @white;
            cursor: pointer;
            padding-top: 0;

            .switch-body-content {
              padding: 0 10px;
              position: relative;
              top: 50%;
              .transform(translateY(-50%));

              .enlarge-image-link {
                display: none;
                position: absolute;
                right: 60px;
                top: 0;

                @media @screen-xl-max {
                  right: 30px;
                }

                i {
                  color: lightgrey;
                  font-size: 150%;
                }
              }

              @media @screen-sm-min {
                &:hover {
                  .enlarge-image-link {
                    display: block;
                  }
                }
              }

              @media @screen-xs-max {
                font-size: 12px;

                .text {
                  word-wrap: break-word;
                }

                &.with-text {
                  padding: 0;
                }
              }
            }
          }

          &__lightblue {
            .switch-body {
              background-color: #78CAF8;
            }
          }

          &.other-channels-special-view {
            .switch-body .switch-body-content.with-text .text {
              line-height: 20px;

              b {
                font-size: 22px;

                @media @screen-xs-max {
                  font-size: 18px;
                }
              }
            }
          }

          &.device .switch-body {
            height: 130px;
          }

          &.device.white-on-click.active .switch-body .switch-body-content {
            filter: brightness(0) invert(1);
          }

          &.active {
            &.reversed {
              .switch-body {
                color: #6f7075;
                background-color: @white;
                border-color: #48b0f9;
              }

              &.default .image {
                -webkit-filter: initial;
                filter: initial;
              }
            }
            .switch-body {
              color: @white;
              background-color: #48b0f9;
            }
          }
        }

        .flex-channels-blocks {
          .flex-block;
          .flex-direction(row);
          .flex-wrap(wrap);
          .justify-content(center);
          border-collapse: collapse;
          border: none;

          .channels-block {
            border-collapse: collapse;
            text-align: center;

            &.border {
              border-right: 4px solid grey;

              @media @screen-md-max {
                &:nth-child(2n) {
                  border: none;
                }
              }

              @media @screen-md-min and @media-lg-max {
                &:nth-child(3n) {
                  border: none;
                }
              }
            }

            &.col-lg-20p {
              @media @screen-lg-min {
                width: 20%;
              }
            }

            .switch.flexible.channel {
              width: 100%;
            }

            .overlap-right-border {
              margin-right: -5px;
              border-right: 6px solid #CEDBE3;

              &.block-title {
                .btn {
                  cursor: unset;

                  &:hover {
                    color: #e8e8f0;
                  }
                }
              }
            }
          }
        }

        &.bold-switches .switch .text {
          font-weight: 700;
        }

        .programme {
          height: auto;
          width: 100%;
          padding: 0 15px;
          background-color: #242424;
          color: @white;
          margin-top: -10px;

          .switch-body {
            padding: 10px 0;

            @media @screen-xs-max {
              padding: 0;
            }

            .switch-body-content {
              height: 100px;
              width: 100%;
              padding-left: 140px;
            }
          }

          &::before,
          &:last-child:after {
            position: relative;
            margin: auto;
            top: 0;
            width: 100%;
            border-top: 3px solid #393939;
            content: "";
            display: block;
          }

          &.active::before {
            border-top: 3px solid white;
          }

          &:first-child {
            margin-top: 0;
          }

          &.active {
            color: @white;

            & + .programme::before {
              border-top: 3px solid white;
            }

            &:last-child:after {
              border-top: 3px solid white;
            }

            .switch-body {
              background-color: #48b0f7;
            }
          }

          .image {
            display: inline-block;
            float: left;
            height: 100%;
            width: 120px;
            margin-left: -135px;
            background: center/contain no-repeat;

              @media @screen-xs-max {
                max-height: 80px;
              }
          }

          .text {
            display: inline-block;
            float: left;
            min-height: 60px;
            height: 100%;
            width: 90%;
            font-weight: 700;

            max-height: none;
          }

          &.expandable span.arrow {
            line-height: 120px;
            float: right;
          }

          .md-button {
            text-align: left;
          }
        }

        &.small-switch-container {
          .box-shadow(~"none!important");
          padding-left: 0px!important;
          padding-right: 40px!important;

          @media screen and (max-width: 520px) {
            padding-left: 0px !important;
            padding-right: 10px !important;
          }

          .loader-wrapper {
            margin-left: 40px;

            @media screen and (max-width: 520px) {
              margin-left: 10px;
            }
          }

          .switch {
            margin: 0 !important;
            width: 100%;
            height: auto;

            &:nth-child(even) {
              background-color: #E5E4E9;
            }

            &:nth-child(odd) {
              background-color: #F7F7F7;
            }

            &-body {
              height: auto;
              margin-top: 0;
              width: 100%;
              border: none;
              background-color: inherit;
              padding-top: 5px;
              padding-bottom: 5px;

              .image {
                display: none;
              }

              .switch-body-content {
                .transform(none);
              }

              .text {
                text-align: left;
                padding-left: 15px;
              }
            }

            &.active {
              .switch-body {
                height: 100%;
                color: black;
                background-color: rgba(1, 1, 1, 0.4);
              }
            }
          }
        }

        &#catch-up {
          .switch {
            margin-top: 8px !important;

            &.with-image,
            &.without-image {
              background-color: #242424;

              .text {
                color: @white;
                font-weight: bold;
              }
            }

            .default.with-image {
              background-color: red !important;
            }
          }
        }

        .panel-group.accordion .panel {
          .panel-heading a {
            &.accordion-toggle {
              &:before {
                content: "\f0d7";
                font-family: 'FontAwesome';
                font-size: 24px;
                position: absolute;
                right: 5px;
                top: 10px;
              }

              &:after {
                display: none;
              }
            }
            &.active {
              background-color: #62b95d !important;
              color: white !important;
            }
          }

          .panel-collapse .panel-body {
            border: 1px solid #48b0f7 !important;
          }
        }
      }

      .double-question-body {
        .calc(height, "100% - 60px");
        overflow: auto;

        &, & .mCSB_container {
          .flex-block;
          .justify-content(space-between);

          @media @screen-lg-max {
            .flex-direction(column);
          }
        }

        .question-body {
          width: 100%;

          @media @screen-lg-max {
            display: table;
            .flex-shrink(0);
            width: 100%;

            &:not(:first-child) {
              margin-top: 20px;
            }
          }

          &:first-child {
            float: left;
          }

          &:last-child {
            float: right;
          }

          .panel {
            width: 100%;
          }
        }

        .slider-header {
          text-align: right;
        }

        .ui_tpicker_time .ui_tpicker_time_input {
          font-weight: 500;
          text-align: right;
        }

        .ui-datepicker-title {
          font-size: 16px;
          text-align: right;
          margin-right: 0;
        }

        .panel {
          width: 100%;
        }

        .empty-column {
          width: 6%;
          .flex-shrink(0);

          &:first-child, &:last-child {
            display: none;
          }
        }
      }

      .search-other-wrapper.without-search {
        @media @screen-xs-max {
          padding-right: 10px
        }

        .question-body {
          @media @screen-xs-max {
            padding-right: 20px !important;
          }

          .mCustomScrollBox {
            .mCS-light.mCSB_vertical.mCSB_outside {
              @media @screen-xs-max {
                padding-right: 5px;
              }
            }
          }

          &.mCustomScrollbar {
            .mCSB_outside + .mCSB_scrollTools {
              right: 0px;
            }
          }
        }
      }

      &.has-info {
        .body-header {
          height: 50px;
          min-height: 50px;
          width: 100%;
          background-color: @white;
          margin-bottom: 20px;

          @media @screen-xs-max {
            margin-top: 10px;
          }

          &.programme-info {
            color: @white;
            background-color: #2f343a;
            height: auto;

            .h100 {
              height: 100%;
            }

            & > .body-header-images {
              display: inline-block;
            }

            & > .body-header-images > div {
              display: inline-block;
              background: transparent;
            }

            & > .body-header-text {
              background-color: transparent;
              vertical-align: middle;
              display: inline-block;
              height: 100%;
            }

            & > .body-header-text :first-child {
              background-color: transparent;
            }
          }

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inherit;
          }
        }
      }

      .body-header + .question-body {
        margin-top: 0;
      }

      .allcp-form {
        .gui-input {
          border-top: none;
          border-left: none;
          border-right: none;
        }


        @media @screen-xs-max {
          .prepend-icon {
            & > .field-icon {
              display: initial;
            }
            & > input {
              padding-left: 40px;
            }
          }
        }
      }

      .letters {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        .flex-block;
        .flex-direction(column);
        .justify-content(space-between);
        overflow: auto;
        z-index: 1;
        padding-bottom: 20px;

        .letter {
          text-align: center;
          line-height: 1;
          cursor: pointer;
        }
      }
    }

    .diary-questions-footer {
      width: 100%;
      height: 50px;
      margin-top: -15px;
      padding: 0 60px;
      position: relative;

      button.btn-disabled {
        color: #fdfcf8;
        background-color: #9b9ead;
      }

      .btn {
        padding-top: 2px;
        padding-bottom: 2px;

        .fa {
          font-size: 32px !important;
        }
      }

      .progress {
        width: 100%;
        margin: 15px 0;
      }
    }

    //todo: seems that this wrapper can be moved inside .question
    .search-other-wrapper {
      position: relative;
      .calc(height, "100% - 60px");
      margin-top: 20px;
      overflow: hidden;

      margin-bottom: 25px;

      padding: 20px;
      padding-left: 30px;
      background-color: white;

      .question-body {
        padding-left: 0 !important;
        padding-right: 20px !important;

        &.small-switch-container .switch.active .switch-body {
          height: 100%;
          color: white;
          background-color: #48b0f7;
        }
      }

      .letters {
        padding-bottom: 15px;
        padding-top: 15px;
        right: 0px;
        width: 55px;
        overflow: hidden;
        background-color: inherit;

        @media screen and (max-height: 768px) {
          padding-bottom: 5px;
          padding-top: 5px;
          font-size: 11px;
        }

        .letter:nth-child(even) {
          @media screen and (max-height: 600px) {
            display: none;
          }
        }
      }

      input {
        padding-right: 35px;
        .calc(width, "100% - 35px");
        float: left;
      }

      .switch .switch-body .switch-body-content .text {
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 5px;
      }

      &.without-search {
        #search-other-channels,
        #search-other-stations {
          height: 100% !important;
          margin-right: 0;
          padding-top: 0;
          padding-bottom: 0;
        }

        .question-body {
          padding-right: 30px !important;
        }
      }
    }
  }

  &.radio-section .diary-questions .question-body .programme .switch-body .switch-body-content {
    height: 50px;
    padding-left: 0;
  }

  .timepicker {
    &-wrapper {
      @media screen and (max-width: 570px) {
        padding-right: 25px;
        padding-left: 25px;
      }
      @media @screen-xs-max {
        padding-right: 10px!important;
        padding-left: 0px!important;
      }
    }

    .ui-timepicker-div {
      .ui_tpicker_time {
        color: #494b57;

        .ui_tpicker_time_input {
          border-bottom: none;
          text-align: center;
        }
      }

      dl .ui_tpicker_hour,
      dl .ui_tpicker_minute {
        top: 6px;
      }
    }

    .ui-slider {
      height: 18px;

      .ui-slider-handle {
        border: 2px solid #4da6de !important;
        width: 24px;
        height: 24px;
      }
    }

    &-wrapper {
      color: #4f535f;
      padding-right: 55px;
      padding-left: 55px;

      .ui-slider {
        background-color: #888;
        height: 16px;

        .ui-slider-handle {
          height: 25px;
          width: 25px;
          top: -1px;
          margin-left: -1em;
        }

        .ui-slider-range {
          display: none;
        }
      }

      .slider-item:not(:first-child) {
          margin-top: 30px;

        @media @screen-xs-max {
          margin-top: 20px;
          //margin-bottom: 35px;
        }

        @media screen and (max-height: 650px) {
          margin-top: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .centered-text {
    text-align: center;
  }

  .alert-dismissable {
    z-index: 9;
    position: fixed;
  }

  .diary-section-label {
    position: relative;
    right: 0;
    top: 10px;

    @media @screen-xs-max {
      top: 2px;
    }

    div {
      background-color: @white;
      position: absolute;
      right: 0;
      font-size: 14px;

      @media @screen-xs-max {
        font-size: 13px;
      }

      span {
        text-transform: uppercase;
        padding-left: 5px;
        padding-right: 5px;
      }

      & .section-logo {
        height: 30px;
        line-height: 0;

        img {
          height: 100%;
          padding: 5px;
        }
      }
    }
  }
}

.body-header-text .name-text {
    font-weight: bold;
    overflow: inherit;
}

.time-text .body-header-text {
    overflow: inherit;
}

.slider {
  &-item {
    .flex-block;
    .align-items(center);

    &:last-child {
      margin-bottom: 35px;
    }

    .ui-slider {
      flex-grow: 1;
    }

    .slider-text {
      width: 120px;
      text-align: right;
      padding-right: 15px;
      font-size: 18px;
      font-weight: 600;

      @media @screen-xs-max {
        width: 90px;
      }
    }
  }

  &-header {
    text-align: center;
    font-weight: 600;
    margin-bottom: 50px;

    .text {
      font-size: 16px;
    }

    .value {
      font-size: 36px;
    }
  }

  .panel {
    height: 250px;

    .slider-wrapper {
      top: 50%;
      transform: translateY(-70%);
    }
  }

  &.timeslider {
    .ui-slider-legend {
      top: 25px;
      font-weight: 600;
      left: -2px;

      font-size: 12px;
      line-height: 0;

      .number {
        top: 1.5em;
      }

      &.over {
        top: -25px;

        .line {
          top: 1.5em;
        }

        .number {
          top: 0;
        }
      }

      @media @screen-xs-max {
        &.marker:nth-child(even) {
          display: none;
        }
      }
    }

    .panel {
      height: auto;
      margin-bottom: 0;
      z-index: 1;
      //padding-bottom: 45px!important;

      @media @screen-xs-max {
        //height: 270px;
        margin-bottom: 0;
      }
    }
  }
}

#search-other-channels .ng-leave,
#search-channels-recorded .ng-leave ,
#search-other-stations .ng-leave {
    display: none;
}

/*Recorded section*/
.channel-bar {
  .channel-bar(){
    height: 50px;
    background: transparent;
    color: @white;
  };
  .channel-bar();

   button,
   button:active,
   button:hover {
     .channel-bar();
   }

  &.white-background {
    color: #000;

    button,
    button:active,
    button:hover {
      color: #000;
    }
  }

  button {
    &:active {
      color: #cacaca !important;
    }

    &.passive {
      color: #999 !important;
      pointer-events: none;
    }
  }

  .allcp-form .gui-input{
    height: auto;
  }

  .channel-image {
    height: 100%;
  }

  span.fa.fa-angle {
    &-right, &-left {
      font-size: 36px;
    }
  }

  .btn:focus {
    color: inherit;
  }
}

.date-bar {
  height: 50px;
  background: #2c2c2c;
  text-align: center;
  overflow: hidden;
  position: relative;

  .date-bar-container {
    position: absolute;
    width: 1000%;
    left: 0;
    transition: 0.1s ease-in-out;

    .date-bar-block {
      float: left;
      min-height: 50px;
      color: @white;
      //min-width: 3.333333333%;
      min-width: 1.429%;

      &:not(.active):not(.passive),
      &.passive {
        background: #2c2c2c;
      }

      &:not(.active):not(.passive):hover {
        background: #515151;
      }

      &.passive {
        display: none;
      }

      &.active {
        background: #7a7a7a;
      }

      span {
        width: 100%;
        float: left;
        line-height: 25px;
      }
    }
  }
}

.other-channels-bar {
  .other-channels-bar(){
    height: 50px;
    background: #2c2c2c;
    color: @white;
  }
  .other-channels-bar();

  button,
  button:hover,
  button:active {
    .other-channels-bar();
  }
}

.allcp-form {
  .smart-widget .button {
    line-height: 45px;
  }

  .prepend-icon .field-icon {
    &.clear-icon {
      left: initial;
      padding: 0;
      margin-top: 13px;
      line-height: 18px;
      color: white;
      pointer-events: all;
      position: relative;
      float: right;
    }

    &.rounded {
      border-radius: 15px;
      background-color: #e9e9e9;
    }

    &.bordered {
      border-left: 1px #e9e9e9 solid;
      border-right: 1px #e9e9e9 solid;
    }
  }

  &.checkbox-websites {
    height: auto !important;
  }
}

#catch-up {
  &, &.mCustomScrollbar .mCSB_horizontal .mCSB_container {
    .flex-block;
    .flex-direction(column);
    .flex-wrap(wrap);
    height: 100%;
    .align-content(flex-start);

    margin-top: 10px;
  }

  &:after {
    @media screen and (max-width: 698px) {
      .calc(width, "100% + 40px");
    }

    @media screen and (max-width: 520px) {
      .calc(width, "100% + 10px");
    }
  }

  .switch {
    flex-shrink: 0;
    .calc(width, "50% - 40px");
    margin-left: 40px!important;
    height: 60px;

      @media screen and (max-width: 698px) {
        .calc(width, "100% - 40px");
      }

      @media screen and (max-width: 520px) {
        margin-left: 10px!important;
        .calc(width, "100% - 10px");
      }

    &:focus {
      outline: 0;
    }

    &:after {
      .calc(width, "50% + 40px");
      content: " ";
      height: 0.1%;
    }

    .switch-body {
      padding-top: 0;
      height: 60px;

      .switch-body-content {
        display: table;
        padding: 0;
        padding-right: 10px;
        width: 100%;
        height: 100%;
        top: 0;
        .flex-block;
        .flex-shrink(0);
        .align-items(center);
        .border-box;

        & > div {
          vertical-align: middle;
          display: block;
        }
        & > *:last-child {
          float: right;
        }

        .image {
          height: 45px;
        }

        .button {
          line-height: 30px;
          height: auto;
          font-weight: bold;
          width: 75px;
        }

        .ondemand & {
          .text {
            padding-top: 0;
            .flex-grow(1);
            max-height: 60px;

            word-break: break-all;
            border: 2px solid rgba(0, 0, 0, 0);
            border-right: 10px solid rgba(0, 0, 0, 0);
          }

          .image {
            width: 80px;
            border: 5px solid rgba(0, 0, 0, 0);
            height: 100%;
            flex-shrink: 0;
          }

          .image + .text {
            padding-left: 5px;
          }
        }
      }
    }
  }

  .ng-hide, .ng-leave {
    display: none;
  }

  .mCSB_horizontal {
    margin-right: -1575px;
  }

  .md-button {
    white-space: inherit !important;
  }
}

.ondemand {
  &-search {
    background: none;
    margin-top: -10px;

    .gui-input {
      border: none;
    }
  }

  input[type="search"] {
    .box-sizing(~"border-box !important");
  }
}

.search-body {
    background-color: white;
    position: relative;
}


.not-found {
  .bold-text {
    font-weight: 800;
  }

  .gray-text {
    color: #9b9ead;
  }
}

.ondemand-search-field {
  height: 47px;

  .gui-input {
    position: absolute;
  }

  .service-image {
    margin-top: 0;
    padding-top: 13px !important;
    height: 100%;
    max-width: 80px;

    & > img {
      width: 90%;
    }
  }
}

.epg-block {
    height: 100% !important;
}

.epg-screen {
    & .mCSB_container {
        min-height: 100%;
    }

    & .mCSB_container {
        min-height: 100%;
    }
}

.body-header.date-bar-header + .epg-block {
    height: 90% !important;
}

.skippable-timepicker {
  .ui-datepicker-inline {
    border-bottom: none;
  }

  .checkbox-websites {
    border: 1px solid #DDD;
    border-top: none;

    &.panel {
      margin-bottom: 0 !important;
    }
  }
}

.panel + .checkbox-websites {
  margin-top: -1px;

  @media @screen-xs-max {
    padding-top: 0;
    margin-top: -20px !important;
  }
}

.service-type-label {
  position: absolute;
  z-index: 1000;
  width: 140px;
  background-color: #50525f;
  color: white;
  left: 20px;
  padding: 5px 0;

  @media screen and (max-width: 360px) {
    width: 90px;
    left: 5px;
  }

  & > div {
    float: left;
  }

  .text {
    width: 90px;
    word-wrap: break-word;
    font-size: 24px;
    line-height: 1.3em;
    font-weight: 800;

    @media screen and (max-width: 360px) {
      width: 60px;
      font-size: 16px;
    }
  }

  &.wide {
    .text {
      width: 105px;
    }

    .video-service-icon,
    .text-service-icon {
      width: 35px;
      height: 40px;
    }
  }
}

//todo: .diary-flow .diary-questions .question .search-other-wrapper ?
#search-other-channels,
#search-other-stations {
  margin-bottom: 30px;
  margin-right: -100px;
  display: block;
  .calc(height, "100% - 40px");
  padding-bottom: 10px !important;

  .search-other-wrapper.without-search & {
    height: 100% !important;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .switch .md-button {
    display: block;
    line-height: inherit;

    min-height: 0;
  }

  .switch-body .text {
    line-height: 26px;
  }

  .loader-wrapper {
    .calc(width, "100% - 135px");
  }
}

.bold-table-headers {
  .device-table {
    .table-row:not(.data-row) {
      .cell {
        font-weight: 800;
      }

      .header-additional-info {
        font-weight: normal;
        font-size: 85%;
      }
    }
  }
}

.device-table {
  .table-row:not(.data-row) {
    .cell {
      font-weight: 800;
    }

    .header-additional-info {
      font-weight: normal;
      font-size: 85%;
    }
  }
}

.device-table {
    background-color: white;

    .flex-direction(column);
    .flex-block;

  .cell {
    padding: 5px;
    width: 100px;
    float: left;
    flex-shrink: 0;
    word-wrap: break-word;

    flex-grow: 4;
    background: #fff;

    .device-undertext {
      font-size:80%;
    }

    &.vertical-center {
      vertical-align: middle;
      .justify-content(space-between);
      .flex-direction(column);
      .flex-block;
    }

    .device-icon {
      height: 30px;
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;

      margin-bottom: 5px;
    }

    .channel-icon {
      max-height: 60px;
      min-height: 50px;
      max-width: 200px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .channel-text {
      margin-bottom: 0.5em;
      margin-top: 0.5em;
    }

    &:first-child {
      text-align: center;
    }

    &.shaded-cell,
    &:nth-child(2n) {
      background-color: #E5EAEE;
    }

    &.no-shading {
      background-color: #FFFFFF;
    }
  }

  td {
    font-weight: normal !important;
  }

  tr > th {
    border-top: none !important;
  }

  .table-row {
    .flex-block;
  }

  .data-row {
    .cell {
      .flex-direction(column);
      .flex-block;
      .justify-content(center);
    }
  }

  .name-column {
    flex-grow: 3;
    min-width: 150px;

    @media @screen-xs-max {
      min-width: 110px;
    }
  }

  &.allcp-form .checkbox {
    background: none;
  }

  @media @screen-sm-min {
      // todo: not sure here about allcp-form
      &.allcp-form .checkbox {
        height: 30px;
        width: 30px;

        &:before {
          top: 4px;
          left: 4px;
          width: 12px;
          height: 6px;
          border-width: 0 0 3px 3px;
        }
      }
  }

  @media @screen-md-max {
    .cell {
      padding: 5px;

      &:first-child {
        padding-left: 15px;
      }
    }
  }

  @media @screen-xs-max {
    .cell {
      &:first-child {
        padding-left: 5px;
      }
    }
  }

  @media @screen-sm-max {
    .cell {
      padding: 5px;
    }
  }
}


// question-body?
.question-table {
  padding: 5px;
  background-color: white;

  .fixed-column-cell {
    position: relative;
    left: 0;
    z-index: 1;
  }

  .fixed-row-cell {
    position: relative;
    top: 0;
    z-index: 1;

    &.fixed-column-cell {
      z-index: 2;
    }
  }

  &.small-table {
    .device-table {
      .cell {
        .channel-icon {
          min-height: 45px;
        }
      }
    }
  }
}

.table {
  .cell {
    &.youtube-duration-name {
      position: relative;

      span {
        position: absolute;
        top: 50%;
        .transform(translate(0%, -50%));
      }
    }
  }
}

// todo: ???
.not-found {
  .tv-icon {
    max-height: 150px;

    @media screen and (max-height: 640px) {
      max-height: 90px;
    }

    @media screen and (max-height: 576px) {
      max-height: 70px;
    }

    @media screen and (max-height: 555px) {
      max-height: 50px;
    }
  }
}

.min-h30 {
  min-height: 30px !important;
}

.min-h25 {
  min-height: 25px !important;
}

.min-h35 {
  min-height: 35px !important;
}

.smart-search-wrapper {
  .flex-block;
  .flex-direction(column);
  height: 100%;
}
