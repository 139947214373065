html,
body,
div.views-wrapper {
    width: 100%;
    height: 100%;
}

.no-text-decoration {
    text-decoration: none!important;
}

iframe {
    display: none;
}

.bg-panel-header {
    background: #00475b;
}

#content {
    padding: 0;
    margin-top: 40px;
}

@media @screen-xs-min {
    #content_wrapper {
        padding-bottom: 30px;
    }
}

@media @screen-xs-max {
    #content {
        margin-top: 0;
    }

    .bg-default-mobile {
        background-color: white;
    }

    .bg-horizontal-padding-color-mobile {
        background: #cfdce4;
    }

    .mobile-horizontal-padding {
        background-color: #cfdce4;
        padding-left: 10px!important;
        padding-right: 10px!important;
    }

    .mobile-horizontal-padding .panel-header {
        margin-left: -10px;
        margin-right: -10px;
    }
}

.panel-header.colorized .panel-title > * {
    color: white;
}

/*@media (max-width: 767px){*/
    .panel-header .panel-title {
        padding-left: 10px;
        padding-top: 15px;
        margin: 0;
    }
    #content .panel .panel-body {
        margin-top: 0;
    }
    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
    }
/*}*/

#content .panel.without-box-shadow {
    box-shadow: none;
}

.errorBlock {
    color: red;
    padding-left: 0;
    padding-right: 0;
}

.todo {
    color: red;
}

em.state-error {
    font-style:normal;
    color: #DE888A;
}

.loader {
  margin: 10px auto;
  border: 8px solid #f3f3f3;
  border-top: 8px solid black;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 0.5s linear infinite;

  &&-center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
  }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#sidebar_left_toggle, #sidebar_top_toggle {
    min-width: 67px;
}

/* fix for opened aside */
.navbar.navbar-fixed-top + #sidebar_left + #content_wrapper {
    margin-left: 0;
}

/* header */
header {
  border-bottom: 1px solid #b8c4cb !important;

  .header-center-block {
    height: 100%;
    line-height: 67px;
    width: 20%;
    text-align: center;
  }

  .header-right-block {
    padding-left: 20px;
    margin-right: 29px !important;
    height: 100%;
    text-align: center;

    .user-block {
      font-size: 12px;
      padding-right: 10px;
      font-weight: 400;
      text-align: right;
      line-height: 47px;

      .first-name {
        font-size: 20px;
        margin-top: -10px;
        line-height: 0;
      }
    }

    .login-block {
      font-size: 18px;
      line-height: 67px;
      font-weight: bold;
    }
  }

  a {
    &, &:focus, &:hover {
      color: black;
    }
  }
}

/* popups */
.popup-closer {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #999;
  font-size: 20px;
  border: 1px solid #999;
  display: block;
  width: 20px;
  height: 20px;
  line-height: 15px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
}

.outer-popup {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 10;

  overflow: auto;
  padding-top: 80px;

  .before-popup {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .inner-popup {
    display: inline-block;
    vertical-align: middle;
    border: 3px solid deepskyblue !important;
    text-align: initial;

    .panel {
      border: none;
    }

    @media (max-width: 440px) {
      top: 100px;
      left: 0;
      width: 100%;
      position: absolute;
    }
  }

  .connection-problems-popup& {
    z-index: 1100;

    .inner-popup {
      z-index: 1102;
      position: relative;
      top: 100px;
    }
  }
}

.semi-transparent-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.7;
    filter: alpha(opacity=70);
}

body:not(.sb-l-c) {
    overflow: hidden !important;
}

.pagelogo {
  display: none;
}

@media @screen-xs-max {
    #content_wrapper {
        display: table;
        width:100%;
        height:100%;
    }
    .pagelogo {
        display:table-row;
        height:0;
    }
    .pagelogo img {
        display:table-cell;
        width:100%;
    }
    #content {
        display:table-cell;
    }
}

#content .panel {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.btn.btn-default.active {
    color: white;
    background-color: #48b0f7;
}

/* forms */
.allcp-form {
  .option {
    line-height: 1.3;
  }

  .checkbox {
    top: 1px;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-radius: 3px;
  }

  radio-button + .checkbox {
    pointer-events: none;
  }

  input:checked + .checkbox,
  radio-button.active + .checkbox {
    border-width: 2px;
    background: #48b0f7;

    &:before {
      margin: 3px;
      border-color: #ffffff !important;
    }
  }

  .select {
    & > select {
      border: none;
      color: #243140;
      background: #DBDBDB;

      &.grouping-select optgroup {
        background: #fff;
      }
    }

    .arrow {
      top: 13px;
      color: #243140;
    }
  }

  .select {
    border-width: 2px;
  }

  .gui-input,
  .gui-textarea,
  .select > select,
  .select-multiple select {
    &, &:focus {
      border-width: 2px;
    }
  }

  .table {
    display: table;

    .table-row {
      display: table-row;

      .table-cell {
        display: table-cell;
      }
    }

    .table-header {
      display: table-header-group;
    }
  }
}

.multiple-checkbox {
  font-weight: 500;

  label {
    text-align: center;
  }

  .ng-leave {
    display: none !important;
  }
}

.float-none {
    float: none !important;
}


select.fa-for-option {
    font-size: large;
    font-family: 'FontAwesome', Arial;
}

.header-logo-image {
  height: 66px;
  max-height: 66px;
  padding: 15px;

  @media screen and (max-width: 450px) {
    padding: 0;
    padding-top: 15px;
    height: auto;
  }

  @media screen and (max-width: 420px) {
    max-width: 33%;
    padding-top: 20px;
  }
}

.navbar {
  background-color: #cfdce4;

  .navbar-left {
    border-right: 1px solid #b8c4cb;
  }

  .navbar-right {
    border-left: 1px solid #b8c4cb;
  }

  .nav.navbar-logo {
    float: none;
    width: auto;
    display: block;
    text-align: center;
  }

  @media screen and (max-width: 390px) {
    .navbar-right {
      margin-right: 10px !important;
      padding-left: 5px;
    }

    .nav.navbar-logo > img {
      max-width: 120px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.social-grade-normal {
    font-weight: normal;
}


.title-bold {
    font-weight: bold;
}

/* slider */
.slider-wrapper {
  background: none;

  .ui-slider {
    height: 20px;
    background: #E5E5E5;

    .ui-slider-handle {
      width: 25px;
      height: 25px;
    }
  }
}

.ui-slider-legend {
  position: relative;

  p {
    position: absolute;
    width: auto;
    display: inline-block;
  }
}
/* slider end */

.no-table {
  display: inherit !important;

  #content {
    display: inherit !important;
  }
}

.left0 {
    left: 0!important;
}

.sb-l-c #sidebar_left.w275 {
    left: -275px;
    overflow: hidden;
}

.ng-leave-prepare, .ng-enter-prepare {
    display: none!important;
}

ul.inline-block {
    display: inline-block;
    vertical-align: top;
}

.anchor{
  display: block;
  height: 67px; /*same height as header*/
  margin-top: -67px; /*same height as header*/
  visibility: hidden;
}

.step-name {
    font-weight: bold;
    text-decoration: underline;
}

@media @screen-sm-min {
    .vertical-centered-sm {
        display: flex!important;
        align-items: center;
    }
}

.mh-350 {
    max-height: 350px !important;
}

.t-left {
    text-align: left;
}

.instruction-screen {
    padding-left: 12%;
}

.float-left-sm {
    float: left;
}

.float-right-sm {
    float: right;
}

.empty-column-4 {
    width: 4%;
    min-height: 1px;
    float: left;
}

#content .panel-group.accordion .panel-instruction .panel-heading,
.panel-instruction {
    color: #48b0f7!important;
}

.panel-instruction {
    border: 2px solid #ffbc0b;
}

.bordered-images img {
    border: 2px solid black;

    width: 280px;
}

@media @screen-sm-max {
    .float-left-sm {
        float: none;
    }

    .float-right-sm {
        float: none;
    }

    .instruction-screen {
        padding-left: 0;
    }
}


.background-section {
  background: #ffffff url('../../assets/images/backgrounds/blue-divan.svg') center center no-repeat fixed;
  background-size: cover;
  min-height: 100%;

  &.lazyloaded {
    background-image: url('../../assets/images/backgrounds/blue-divan-1600x746.jpg');
  }

  &.profile {
    background-image: url('../../assets/images/backgrounds/profile_01.svg');

    &.lazyloaded {
      background-image: url('../../assets/images/backgrounds/profile_01.jpg');
    }
  }

  &.learn-more {
    background-image: url('../../assets/images/backgrounds/learn_more_01.svg');

    &.lazyloaded {
      background-image: url('../../assets/images/backgrounds/learn_more_01.jpg');
    }
  }

  &.rewards {
    background-image: url('../../assets/images/backgrounds/rewards_01.svg');

    &.lazyloaded {
      background-image: url('../../assets/images/backgrounds/rewards_01.jpg');
    }
  }

  &.devices {
    background-image: url('../../assets/images/backgrounds/devices_01.svg');

    &.lazyloaded {
      background-image: url('../../assets/images/backgrounds/devices_01.jpg');
    }
  }

  &.helpdesk {
    background-image: url('../../assets/images/backgrounds/helpdesk_01.svg');

    &.lazyloaded {
      background-image: url('../../assets/images/backgrounds/helpdesk_01.jpg');
    }
  }

  &.diary {
    background-image: url('../../assets/images/backgrounds/diary_01.svg');

    &.lazyloaded {
      background-image: url('../../assets/images/backgrounds/diary_01.jpg')
    }
  }
}

.red {
  color: red;
}

.gray-instruction-border{
  border: 5px solid #58585B;
}

.wrap-link {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
}

.os-logo {
  max-height: 40px;
  width: audot;
}

.instruction-link {
  line-height: 40px;
}

.incompass-app {
  .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .control-icons-container .close:before {
    background-image: url(../../assets/img/gallery-icons/icons/close.svg);
  }

  .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .actions-icons-container .delete-img:before {
    background-image: url(../../assets/img/gallery-icons/icons/delete.svg);
  }

  .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .control-icons-container .ext-url:before {
    background-image: url(../../assets/img/gallery-icons/icons/external-link.svg);
  }

  .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .prev {
    left: 0px;
    background-image: url(../../assets/img/gallery-icons/icons/prev.svg);
  }
  .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .next {
    right: 0px;
    background-image: url(../../assets/img/gallery-icons/icons/next.svg);
  }
  .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .control-icons-container .ext-url:before {
    background-image: url(../../assets/img/gallery-icons/icons/external-link-inline.svg);
  }
  .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .prev {
    background-image: url(../../assets/img/gallery-icons/icons/prev-inline.svg);
  }
  .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .next {
    background-image: url(../../assets/img/gallery-icons/icons/next-inline.svg);
  }
  .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .actions-icons-container .delete-img:before {
    background-image: url(../../assets/img/gallery-icons/icons/delete-inline.svg);
  }
}

.maintenance-notification {
  display: none;
  background-color: #46c27a;
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1030;

  .head {
    font-size: 30px;
    line-height: 30px;
    padding-top: 11px;

    @media @screen-xs-max {
      font-size: 27px;
    }
  }

  .notification-text {
    margin-bottom: 11px;
    line-height: 14px;
    padding-top: 2px;
  }
}

#sidebar_left_toggle, #sidebar_top_toggle {
  margin-top: 0;
}

.maintance-message-visible {
  &.maintenance-notification {
    display: block;
  }

  &+.navbar {
    top: 68px;

    @media (max-width: 715px) {
      top: 82px;
    }

    @media @screen-xs-max {
      top: 78px;
    }

    @media (max-width: 384px) {
      top: 96px;
    }

    &+#sidebar_left {
      top: 68px;

      @media (max-width: 715px) {
        top: 82px;
      }

      @media @screen-xs-max {
        top: 78px;
      }

      @media (max-width: 384px) {
        top: 96px;
      }
    }

    &.navbar-fixed-top +#sidebar_left +#content_wrapper {
      padding-top: 124px;

      @media (max-width: 715px) {
        padding-top: 148px;
      }

      @media (max-width: 384px) {
        padding-top: 162px;
      }
    }
  }
}


@media (min-width: 768px) {
  .col-sm-1_5 {
    width: 12.5%;
    float: left;
    min-height: 1px;
  }
}
