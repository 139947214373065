/* from diary-flow file */
.w100p {
    width: 100%;
}

.ml-minus-60 {
    margin-left: -60px;
}

.mr-minus-60 {
    margin-right: -60px;
}

.lh50 {
    line-height: 50px !important;
}

.w-20 {
    width: 20px!important;
}

.h-20 {
    height: 20px!important;
}

.lh50 {
  line-height: 50px !important;
}

.mw160-400 {
    max-width: 160px;
}

.mw240-500 {
    max-width: 240px;
}

.w-65 {
    width: 65%;
}

.mh-55 {
    max-height: 55px;
}

.mh-120 {
    max-height: 120px !important;
}

@media @screen-sm-min {
    .mw160-400 {
        max-width: 400px;
    }

    .mw240-500 {
        max-width: 500px;
    }
}
