.profile-detail-block {
    border: 2px solid black;
    border-radius: 4px;
    padding: 10px;
    max-width: 550px;
    margin: 0 auto 10px;
}

.profile-detail {
    .bordered-box {
        padding: 10px!important;
        margin: 0px!important;
        margin-bottom: 22px!important;
    }
}

.info-form {
    max-width: 450px;
    margin: 0 auto;
}

.button-group {
    margin-top: 20px;
    text-align: right;
}

.profile-detail .profile-detail-stretch {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

.profile-detail-stratch-child {
    height: 100%;
}

#content .profile-detail .panel {
    box-shadow: none;
    -webkit-box-shadow: none;
}

@media (max-width: 767px) {
    .profile-detail .profile-detail-stretch {
        position: relative;
    }
}

.big-number {
    font-size: 2em;
    line-height: 20px;
    display: block;

}

.section-absolute {
    position: absolute;
}

.absolute-right {
    right: 0;
    bottom: 12px
}


.mb22 {
  margin-bottom: 22px !important;
}

.blocks-background {
    position: relative;
}

@media (min-width: 769px) {
    .profile-detail .panel-header {
        display: none;
    }

    .profile-detail.panel {
        background: transparent;
    }
}

@media (max-width: 768px) {
    .profile-detail .panel.bordered {
      border: 1px gainsboro solid;
    }
    .blocks-background {
      padding: 0 10px 10px;
    }
}

@media (max-width: 480px) {
    .blocks-background {
        background-color: #fff;
        margin: 0 15px 0;
        padding-left: 0;
        padding-right: 0;
    }

    .profile-detail {
      position: relative;
    }
    .profile-statistic, dt {
      padding-top: 1px!important;
    }
    /*.profile-detail .panel-header {*/
        /*display: block;*/
    /*}*/
}

.panel-heading {
  font-size: 1.5em;
}

.profile-statistic, dt {
  color: #4D515C;
}
