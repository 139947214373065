.welcome-wrapper {
  min-height: 100%;

  .diary-questions {
    min-height: 100%;
  }
}

.diary-welcome {
  min-height: 100%;
  width: 100%;
  z-index: 11;
  color: #ffffff;
  background: #267298 center/cover no-repeat;
  .flex-block;
  .flex-direction(column);
  .justify-content(flex-end);

  &-text {
    margin-bottom: 15px;
  }

  .big-box {
    flex-grow: 2;
    position: relative;
    flex-shrink: 0;
  }

  .small-box {
    flex-grow: 1;
    position: relative;
  }

  &.welcome {
    background-image: url(assets/img/diary-welcome-screens/welcome.png);
  }

  &.tv-live {
    background-image: url(assets/img/diary-welcome-screens/tv-live.png);
  }

  &.tv-recorded {
    background-image: url(assets/img/diary-welcome-screens/tv-recorded.png);
  }

  &.tv-ondemand {
    background-image: url(assets/img/diary-welcome-screens/tv-ondemand.png);
  }

  &.radio {
    background-image: url(assets/img/diary-welcome-screens/radio.png);
  }

  &.websites {
    background-image: url(assets/img/diary-welcome-screens/websites.png);
  }

  .header {
    max-width: 320px !important;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    line-height: 1.3;
  }

  .text-wrapper {
    text-align: left !important;

    .text {
      max-width: 80vw !important;
    }
  }

  .logo {
    width: 100%;
  }

  @media (min-width: 992px) {
    .header {
      font-size: 60px !important;
      max-width: 360px !important;
    }

    .text-wrapper .text {
      max-width: 600px !important;
      font-size: 18px;
    }

    .logo img {
      height: 60px;
    }
  }
}

.start-btn {
  border: 2px solid white;

  &-text {
    font-weight: bold;
    font-size: x-large;
  }
}

.abs-xyz-0 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

.without-colors {
  color: transparent;
  background-color: transparent;
}
