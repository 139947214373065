.error {
  color: red;
  font-weight: bold;

  &Block {
    color: red;
    padding-left: 0;
    padding-right: 0;
  }
}

.radiochoices {
  padding-left: 15px;
  text-align: left;

  &__label {
    padding-top: 0 !important;
  }

  label {
    display: block;
  }
}

.bordered-box {
  border: 8px grey solid;
}

.icon-from-image {
  height: 22px;
  width: 21px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.standart-phone-icon {
    background-image:url(../../assets/img/icons/phone-icon.png);
    opacity: .5;
  }
}



.signup {
  &__button-group {
    margin-top: 20px;
    text-align: right;
  }

  .date__inputs {
    padding-left: 15px;
  }

  #content & .panel {
    .box-shadow(none);
  }
}

.popup {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:before {
    display: block;
    content: ' ';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: .5;
  }

  &-content {
    height: 210px;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 20px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  &-closer {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #999;
    font-size: 20px;
    border: 1px solid #999;
    display: block;
    width: 20px;
    height: 20px;
    line-height: 15px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }

  #content & .panel {
    position: absolute;
    margin-bottom: auto;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

.float-right-lg {
    float: right;
}

@media @screen-xs-max {
    #content {
        padding:  0;

      .panel .panel-heading.signup-confirm {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-color: #74CD61;
        padding: 10px;
        padding-top: 15px;
      }
    }

    .signup {
        padding: 0;
    }

    .float-right-lg {
        float: none;
    }

    .signup-confirm-body {
      margin-top: 15px!important;
    }
}

@media @screen-xs-min {
  .signup .text-white {
      color: initial;
  }
}

@media screen and (max-width: 991px) {
    .signup .name-field {
        margin-bottom: 22px!important;
    }
}

.trash {
  display: none;
}
