.rewards {
   & & {
     &-image {
       background: #267298 center/cover url(assets/img/rewards/rewards-image.png) no-repeat;
     }

     &-content {
       color: #2c3240;
     }

     &-auth #allcp-form {
       .col-sm-2:not(.col-sm-offset-1) {
         display: none;
       }

       .col-sm-6,
       .col-sm-3 {
         width: 100%;
       }

       .col-sm-2.col-sm-offset-1 {
         margin-left: 0;
         width: 100%;
       }
     }
   }
}

.slick {
  &-next {
      right: -30px;
  }

  &-prev {
      left: -30px;
  }

  &-dots li button:before {
      font-size: 12px;
  }

  &-slider {
    height: 200px;
    max-width: 200px;
    color: #565660;
    border: 1px solid #c1c1c1;

    &-prev:before,
    &-next:before {
      font-size: 25px;
      opacity: 1;
      color: #48b0f9;
      background-color: #ffffff;
    }

    .slick-slide {
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      padding: 30px 10px;

      &.no-money {
        img, .description {
          opacity: 0.3;
        }
      }

      .slide-label {
        position: absolute;
        overflow: visible;
        top: 0;
        left: 0;
        color: #ffffff;
        width: 0;
        height: 0;
        z-index: 1;
        border-top: 110px solid #f6393d;
        border-right: 150px solid transparent;

        p {
          position: absolute;
          white-space: pre;
          text-align: left;
          top: -100px;
          left: 10px;
          z-index: 2;
          width: 98px;
          height: 57px;
          color: #ffffff;
          font-size: 16px;
          line-height: 1.1;
          font-weight: 600;
        }
      }

      img {
        width: 150px;
        height: 100px;
        margin: auto;
      }

      .description {
        width: 140px;
        line-height: 1.3;
        font-size: 18px;
        font-weight: 500;
        margin: auto;
        margin-top: 15px;
      }
    }
  }
}

@media (min-width: 992px) {
    .mt20-md {
        margin-top: 20px!important;
    }
}
