.diary-page {
  .passive {
    padding-bottom: 0 !important;
    margin-top: 200px;

    .panel-body {
      margin-top: 0 !important;
      padding: 0 0 15px !important;

      h3 {
        margin-top: 10px;
      }

      .empty {
        background-color: #857c73;
      }

      .progress {
        margin-bottom: 0;
      }

      .progress-bar-success {
        padding-left: 0;
      }
    }
  }

  & &-image {
    background: #267298 center/cover url("assets/img/diary-image.png") no-repeat;
  }

  .dashboard-menu-item {
    &.inactive {
      color: #e6eef0;
      background-color: #cfdce2;

      &:hover {
        background-color: #bbc8ce;
      }
    }

    &.completed {
      color: #e6eef0;
      background-color: #73cd61;

      &:hover {
        background-color: #62b95d;
      }
    }

    &.missed {
      color: #ffffff;
      background-color: #f6393d;

      &:hover {
        background-color: #e23939;
      }
    }

    &.active {
      color: #ffffff;
      background-color: #48b0f7;

      &:hover {
        background-color: #459fe3;
      }
    }

    .menu-icon.diary {
      background-image: url('assets/img/dashboard/ic-diary-grey.png');
    }

    &.missed, &.active {
      .menu-icon.diary {
        background-image: url('assets/img/dashboard/ic-diary-white.png');
      }
    }
  }

  .diary-content {
    color: #2c3240;
  }

  .diary-progress-md {
    height: 100%;
    color: #2c3240;
    background-color: #ffffff;
  }

  @media @screen-sm-max and (orientation: landscape) {
    .col-xs-6 {
      width: 20%;
    }
  }

  @media @screen-sm-min {
    .col-md-4 {
      width: 25%;
    }
  }
}

.loader-background {
  background-color: #e9e9e9;

  .inner-popup {
    border: none;
  }
}
