.questions-form {
  max-width: 450px;
  margin: 0 auto;

  .form-group {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  label.control-label + div.control-div {
    margin-left: 10px;
  }
}

.button-group {
  margin-top: 20px;
  text-align: right;
}

.form-container .title {
  margin-bottom: 20px;
}

.pl70 {
  padding-left: 70px !important;
}

.pr70 {
  padding-right: 70px !important;
}

.absolute {
  position: absolute;
}

.right {
  right: 0;
}

.left {
  left: 0;
}

#content .close-questionarie .panel {
  box-shadow: none;
  border: none;
}

.confirm-first-stage-button {
  display: table;

  & > a {
    display: table-cell;
    text-decoration: none;

    & > button {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.questionarie-question {
  .radio-button-column {
    text-align: center;

    .wrap-text {
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 20px;
      height: auto;
      white-space: normal;
      display: inline-block!important;
      word-wrap: break-word;
    }
  }
}

@media @screen-xs-max {
  .questions {
    position: absolute;
    height: 100%;
    width: 100%;
    margin-top: -66px;
    padding-top: 80px !important;

    & > form {
      min-height: 100%;
    }
  }

  .question-navigation {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.specify-field {
  .clo-xs-12 {
    padding-right: 0;
  }

  .row {
    margin-left: 0;
  }

  .mb30 {
    margin-bottom: 0 !important;
  }

  .pb30 {
    padding-bottom: 0 !important;
  }

  .br-b {
    border: none !important;
  }
}

.allcp-form.consent-panel .panel {
    border: none;
}

@media @screen-sm-min {
  .consent-panel .panel-header h1 {
    font-size: 30px !important;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a.disabled-download {
  pointer-events: none;
}

.download-links > a {
  margin: 10px 5px;
  display: inline-table;
  width: 150px;
}
