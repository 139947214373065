.dashboard {
  .mediacell-logo {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-menu {
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    & > div {
      min-width: 228px;
    }

    &-item {
      display: block;
      background-color: #FFF;
      padding: 10px;
      color: #2c3341;
      padding-top: 15px;
      height: 100%;

      b {
        height: 16px;
      }

      &, &:visited, &:focus, &:hover, &:active {
        text-decoration: none;
        color: #FFF;
        background: #48b0f7;
      }

      .fa-caret-down {
        position: relative;
        top: -10px;
      }

      .menu-icon {
        display: inline-block;
        width: 50px;
        height: 50px;
        color: #73cd61;
        background: center/contain no-repeat;

        &.diary {
          background-image: url(../assets/img/dashboard/ic-diary-white.png);
        }

        &.devices {
          background-image: url(../assets/img/dashboard/ic-devices-white.png);
        }

        &.rewards {
          background-image: url(../assets/img/dashboard/ic-rewards-white.png);
        }

        &.me {
          background-image: url(../assets/img/dashboard/ic-me-white.png);
        }

        &.download {
          background-image: url(../assets/img/dashboard/go_down.png);
        }
      }
    }
  }

  .panel .btn-group {
    padding-left: 0;
  }
}
